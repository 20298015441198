import request from "@/utils/request";
const api = {
  getconstructionMateriallist: "/dsmcore/api/constructionMaterial/list", //查询施工物料---未下单接口
  updateconstructionMaterial: "/dsmcore/api/constructionMaterial/update", //更新施工物料
  addOrderconstructionMaterial: "/dsmcore/api/constructionMaterial/addOrder", //物料下单
  // getalreadyorderlist: "/dsmcore/api/scmcore/order/list", //查询物料管理已下单接口
  getalreadyorderlist: "/scmcore/api/order/list", //查询物料管理已下单接口
  deleteorder: "/scmcore/api/order/delete", //取消订单
  logisticslist: "/scmcore/api/logistics/list", //物料列表--发货情况
  orderreturn: "/scmcore/api/order/return", //物料列表--物料下单情况，退货
  logisticsreturn: "/scmcore/api/logistics/return", //物料列表--发货情况--退货
  getselectByIdlist: "/dsmcore/api/material/selectById", //查询供应商
};
export function GetConstructionMaterialList(parameter) {
  return request({
    url: api.getconstructionMateriallist,
    method: "post",
    params: parameter,
    // data: parameter,
  });
}
export function UpdateConstructionMaterial(parameter) {
  return request({
    url: api.updateconstructionMaterial,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function AddOrderconstructionMaterial(parameter) {
  return request({
    url: api.addOrderconstructionMaterial,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}

export function GetAlreadyOrderList(parameter) {
  return request({
    url: api.getalreadyorderlist,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function DeleteOrder(parameter) {
  return request({
    url: api.deleteorder,
    method: "get",
    params: parameter,
    // data: parameter,
  });
}
export function LogisticsList(parameter) {
  return request({
    url: api.logisticslist,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function OrderReturn(parameter) {
  return request({
    url: api.orderreturn,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function LogisticsReturn(parameter) {
  return request({
    url: api.logisticsreturn,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function GetSelectByIdList(parameter) {
  return request({
    url: api.getselectByIdlist,
    method: "post",
    params: parameter,
    // data: parameter,
  });
}
