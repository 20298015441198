<template>
  <div class="ReturnInformation">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <!-- @blur="
              () => {
                $refs.name.onFieldBlur();
              }
            " -->
      <div v-for="item in form" :key="item.id">
        <a-form-model-item
          ref="number"
          :label="item.material.name"
          prop="number"
        >
          <a-input-number
            v-model="item.number"
            :min="0"
            :max="item.sentNumber"
            placeholder="请输入数量"
            @change="inputNumberChange"
          />
        </a-form-model-item>
        <a-form-model-item label="类型" prop="type">
          <a-radio-group v-model="item.type">
            <a-radio value="0"> 退货 </a-radio>
            <a-tooltip placement="right">
              <template slot="title">
                <span>未收到货不可以换货</span>
              </template>
              <a-radio value="1" :disabled="item.checkState == 0">
                换货
              </a-radio>
            </a-tooltip>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item label="请选择是否退回原货" v-if="item.type == 1">
          <a-select
            v-model="item.isBackOriginal"
            placeholder="请选择是否退回原货"
          >
            <a-select-option value="1"> 退回原货 </a-select-option>
            <a-select-option value="0"> 不退回原货 </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="退/换货原因" prop="remark">
          <a-input
            v-model="item.remark"
            type="textarea"
            placeholder="请描述原因"
          />
        </a-form-model-item>
      </div>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 10 }">
        <a-button type="primary" @click="onSubmit"> 确定 </a-button>
        <a-button style="margin-left: 10px" @click="resetForm"> 取消 </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { LogisticsReturn } from "@/api/apiJF/material";
export default {
  name: "ReturnInformation",
  components: {},
  props: {
    record: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      labelCol: { span: 9 },
      wrapperCol: { span: 10 },
      other: "",
      form: [
        {
          material: {
            name: "材料名称",
          },
          number: 0,
          sentNumber: 0,
          type: "",
          isBackOriginal: "",
          remark: "",
        },
        {
          material: {
            name: "材料名称",
          },
          number: 0,
          sentNumber: 0,
          type: "",
          isBackOriginal: "",
          remark: "",
        },
        {
          material: {
            name: "材料名称",
          },
          number: 0,
          sentNumber: 0,
          type: "",
          isBackOriginal: "",
          remark: "",
        },
        {
          material: {
            name: "材料名称",
          },
          number: 0,
          sentNumber: 0,
          type: "",
          isBackOriginal: "",
          remark: "",
        },
        {
          material: {
            name: "材料名称",
          },
          number: 0,
          sentNumber: 0,
          type: "",
          isBackOriginal: "",
          remark: "",
        },
        {
          material: {
            name: "材料名称",
          },
          number: 0,
          sentNumber: 0,
          type: "",
          isBackOriginal: "",
          remark: "",
        },
        {
          material: {
            name: "材料名称",
          },
          number: 0,
          sentNumber: 0,
          type: "",
          isBackOriginal: "",
          remark: "",
        },
        {
          material: {
            name: "材料名称",
          },
          number: 0,
          sentNumber: 0,
          type: "",
          isBackOriginal: "",
          remark: "",
        },
        {
          material: {
            name: "材料名称",
          },
          number: 0,
          sentNumber: 0,
          type: "",
          isBackOriginal: "",
          remark: "",
        },
      ],
      recordForm: [],
      rules: {
        number: [
          // {
          //   required: true,
          //   message: "请输入不大于未发货量的数量",
          //   trigger: "blur",
          // },
          //   {
          //     min: 3,
          //     max: 5,
          //     message: "此处仅支持未发货的物料",
          //     trigger: "blur",
          //   },
        ],
        type: [
          // { required: true, message: "请选择退换货类型", trigger: "change" },
        ],
      },
    };
  },
  created() {
    let record = JSON.parse(this.record);
    console.log("record", record);
    this.recordForm = record;
    // this.form = this.recordForm;
    this.recordForm.forEach((el, recordFormIndex) => {
      // this.form.forEach((item, formIndex) => {
      //   // item.number = el.returnNumber * 1;
      //   // item.remark = "";

      // });
      this.form[recordFormIndex].id = el.id;
      this.form[recordFormIndex].supplierId = el.supplierId; //供应商id
      this.form[recordFormIndex].material = el.material; //材料信息
      this.form[recordFormIndex].sentNumber = el.sentNumber; //
      this.form[recordFormIndex].number = el.sentNumber; //

      // this.form[recordFormIndex].backMaterialCode = el.materialCode; //换货材料编码
      // this.form[recordFormIndex].backNumber = el.materialCode; //收到的换货数量
    });
    this.form = this.form.splice(0, this.recordForm.length);
    // console.log("this.form", this.form);
  },
  mounted() {},
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log("this.form", this.form);
          this.OrderReturn();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.$router.push({
        name: "AlreadyOrder",
      });
    },
    onClose() {
      this.visibleDrawer = false;
    },
    //退货
    OrderReturn() {
      let params = this.form;
      console.log("params", params);
      LogisticsReturn(params).then((res) => {
        // console.log("res", res.data);
        if (res.code == 200) {
          this.$message.success("退货成功");
          this.onClose();
          this.$router.push({
            name: "TakeDelivery",
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    inputNumberChange() {
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="less" scoped>
.alertName {
  margin: 20px;
  width: 70%;
}
/deep/.ant-alert-info {
  background-color: #eaf2ff;
  border: none;
  border-radius: 10px;
}
</style>